@import '../../../../cl_base/scss/theme-bootstrap';

.product-wrapper,
.tout-wrapper {
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 25%;
  padding: 12px;
  @include breakpoint ($cr19-small) {
    width: 100%;
    padding: 0 0 35px;
  }
  h2 {
    height: 150px;
    margin: 0;
    overflow: hidden;
    line-height: 24px;
    font-size: 17px;
    font-family: $base-font-family-roman;
    color: $color-black;
    @include breakpoint ($cr19-small) {
      height: auto;
    }
  }
  .product-badge-wrapper {
    position: absolute;
    top: 10px;
    padding: 5px 10px;
    z-index: 1;
    width: 100%;
    .product-badge {
      font-family: $base-bolder-font-family;
      letter-spacing: 0.08em;
      line-height: 1.5;
      margin: 0;
      text-transform: uppercase;
      color: $color-black;
      font-size: 11px;
      font-weight: 600;
      float: left;
    }
  }
  .product-image-wrapper {
    background-color: $cr19-bg-light;
    cursor: pointer;
  }
  .product-image {
    width: 100%;
    vertical-align: middle;
  }
  .product-image-alt {
    display: none;
    width: 100%;
    vertical-align: middle;
  }
  .product-learn-more-button-mobile-wrapper {
    text-align: right;
    margin: 10px 0 20px;
    .product-shop-now-button {
      margin-right: 5px;
    }
  }
  .product-learn-more-button {
    display: none;
    position: relative;
    height: 0;
    @include breakpoint ($cr19-small) {
      display: inline-block;
      position: inherit;
      height: auto;
      margin-left: 5px;
    }
    button {
      position: absolute;
      width: auto;
      height: 30px;
      bottom: 8px;
      right: 8px;
      padding: 0 8px;
      background-color: $color-white;
      border: 1px solid $color-black;
      font-size: 12px;
      font-family: $base-font-family-roman;
      font-weight: 700;
      letter-spacing: 1.25px;
      text-transform: uppercase;
      @include breakpoint ($cr19-small) {
        position: inherit;
      }
    }
  }
  .product-info-wrapper {
    margin-top: 10px;
    cursor: pointer;
  }
  .product-shades-count-wrapper {
    position: relative;
    .product-shades-count {
      display: none;
      position: absolute;
      bottom: 8px;
      left: 5px;
      font-weight: 700;
      font-size: 15px;
      font-family: $base-font-family-roman;
      color: $color-black;
      @include breakpoint ($cr19-small) {
        display: block;
        bottom: 0;
        top: 15px;
      }
    }
  }
  .product-short-description {
    display: none;
  }
  .product-shade-slider {
    position: relative;
    padding: 0;
    margin: 0;
    .slider-list {
      position: relative;
      display: block;
      overflow: hidden;
      margin: 0;
      padding: 0;
    }
    .slider-track {
      position: relative;
      width: max-content;
      margin: 0;
      padding: 0;
      left: 0;
    }
    .slider-slide {
      height: 25px;
      display: inline-block;
      margin: 0 auto;
      cursor: pointer;
      border: 1px solid $color-btn-inverse;
      &.active {
        border: 1px solid $color-black;
        margin: 0 auto;
      }
    }
    .slider-arrow {
      position: absolute;
      display: block;
      line-height: 0;
      font-size: 0;
      cursor: pointer;
      background: transparent;
      color: #020000;
      height: 100%;
      z-index: 1;
      top: -1px;
      &.left {
        left: 5px;
        right: auto;
        svg {
          transform: rotate(180deg);
        }
      }
      &.right {
        left: auto;
        right: 5px;
      }
      svg {
        width: 11px;
        height: 100%;
        fill: $color-white;
      }
    }
  }
}

html[lang='ar-ME'] {
  .product-wrapper {
    .product-shades-count-wrapper {
      .product-shades-count {
        right: 8px;
        left: auto;
      }
    }
    .product-learn-more-button {
      @include breakpoint ($cr19-small) {
        margin-right: 5px;
        margin-left: 0;
      }
      button {
        left: 8px;
        right: auto;
      }
    }
    .product-learn-more-button-mobile-wrapper {
      text-align: left;
    }
  }
}
